/* global Component */
class footerSectionComponent extends Component {

    static name() {
        return "footerSectionComponent";
    }

    static componentName() {
        return "footerSectionComponent";
    }

    data() {
        return {
            year: new Date().getFullYear(),
            display: false,
            modalContent: '', 
        };
    }

    getComputed() {
        return {
            menuTitle:function (){
                return this.$store.getters.menuTitle;
            },
            menuSubTitle:function (){
                return this.$store.getters.menuSubTitle;
            },
            mustRender:function (){
                return this.$store.getters.settingsIsLoaded;
            },
            enableLK:function () {
                return this.$store.state.setting.EnableLK;
            },
            linkLK: function (){
                let link = "#";
                if(this.$store.state.setting.LKLink)
                    link = this.$store.state.setting.LKLink;
                return link;
            },
            enableIG:function () {
                return this.$store.state.setting.EnableIG;
            },
            linkIG: function (){
                let link = "https://instagram.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.IGLink;
                return link;
            },
            enableFB:function () {
                return this.$store.state.setting.EnableFB;
            },
            linkFB: function (){
                let link = "https://facebook.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.FBLink;
                return link;
            },
            enableTW:function () {
                return this.$store.state.setting.EnableTW;
            },
            linkTW: function (){
                let link = "https://twitter.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.TWLink;
                return link;
            },
            footerTemplateCode: function (){
                return this.$store.state.setting.FooterTemplate;
            },
            socialMediaFooter: function (){
                return this.$store.state.setting.Footer;
            },
            useFooter: function (){
                return this.$store.state.setting.UseFooter;
            },
            size() {
                return this.$store.state.setting.Switch === true ? 4 : 2;
            },
            footerItems() {
                return this.$store.state.setting.FooterRow || [];
            },
            TitleCol1() {
                return this.$store.state.setting.TitleColumn1;
            },
            TitleCol2() {
                return this.$store.state.setting.TitleColumn2;
            },
            TitleCol3() {
                return this.$store.state.setting.TitleColumn3;
            },
            TitleCol4() {
                return this.$store.state.setting.TitleColumn4;
            },
            policy(){
                return this.$store.state.setting.PrivacyPolicy;
            },
            terms(){
                return this.$store.state.setting.TermsAndConditions;
            },
            useModal: function (){
                return this.$store.state.setting.OptionTermsAndPolicy;
            },
        };
    }

    getMethods(){
        return {
            displayModal(option){
                this.display = true; 
                if(option === 'terms'){
                    return this.modalContent = this.$store.state.setting.TermsClauseDescription;
                }else {
                    return this.modalContent = this.$store.state.setting.PolicyClauseDescription;
                }
            }
        };
    }

    getTemplate() {
        return `<footer class="container-fluid" v-if="mustRender">

                <div v-if="socialMediaFooter.length" class="social-bar row text-center text-md-center py-2">
                    <div class="col">
                        <template v-for="sm in socialMediaFooter">
                            <a v-if="sm.fields.Type == 1" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" color="#4067b5" width="30" height="30" viewBox="0 0 530 530"><path fill="#4570ba" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg></a>
                            
                            <a v-if="sm.fields.Type == 2" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#923f7c" d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg></a>
                            
                            <a v-if="sm.fields.Type == 3" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#1da1f2" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-13.9 20.8-31.6 39.1-51.9 53.8z"/></svg></a>
                            
                            <a v-if="sm.fields.Type == 4" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#cc0000" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg></a>
                        
                            <a v-if="sm.fields.Type == 5" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#4067b5" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a>
                        
                            <a v-if="sm.fields.Type == 6" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" viewBox="0 0 530 530"><path fill="#e60019" d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3 .8-3.4 5-20.3 6.9-28.1 .6-2.5 .3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"/></svg></a>

                            <a v-if="sm.fields.Type == 7" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#030303" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg></a>

                            <div v-if="sm.fields.Type == 8" class="social-icon" style="cursor: pointer;"><svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" viewBox="0 0 530 530"><path fill="#25d366" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg><div></div></div>

                            <div v-if="sm.fields.Type == 9" class="social-icon" style="cursor: pointer;"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#000000" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></div>

                            <a v-if="sm.fields.Type == 10" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#000000" d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg></a>

                            <a v-if="sm.fields.Type == 11" class="social-icon" :href="sm.fields.URL" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 530 530"><path fill="#2a4377" d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg></a>
                        </template>
                    </div>
                </div>

                <div v-if="useFooter" class="row justify-content-center py-3" style="background: white">
                    <div class="lean-overlay" v-if="display" @click="display = !display" id="materialize-lean-overlay-3"></div>
                    <div v-if="display" class=" modal modal-full modal-custom ">
                        <div class="modal-header">
                            <span class="close-modal" @click="display = !display" style="cursor: pointer">
                                <i class="close-icon material-icons">close</i>
                            </span>
                        </div>
                        
                        <div class="modal-content" style="background: rgb(245, 245, 245);position: relative;z-index: 100; padding: 2rem 1rem;">
                            <div v-html="modalContent" style="text-align: left"></div>
                        </div>
                    </div>

                    <div class="container">
                        <div :class="size == 2 ? 'space-evelyn' : 'space-between'">
                            <div class="column footer-content">
                                <span class="footer-title column-row" ><strong>{{TitleCol1}}</strong></span>
                                <div v-if="row.fields.Column == 1" v-for="(row, idx) in footerItems" :key="idx">
                                    <a class="contact" v-if="row.fields.Type == 0" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-map-marker-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 1" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-phone-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 2" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-envelope"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a v-else :href="row.fields.PagePath" class="">
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                </div>
                            </div>
                            <div class="column footer-content">
                                <span class="footer-title column-row"><strong>{{TitleCol2}}</strong></span>
                                <div v-if="row.fields.Column == 2"  v-for="(row, idx) in footerItems" :key="idx">
                                    <a  class="contact" v-if="row.fields.Type == 0" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-map-marker-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 1" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-phone-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 2" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-envelope"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a v-else :href="row.fields.PagePath" class="">
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                </div>
                            </div>
                            <div v-if="size === 4" class="column footer-content">
                                <span class="footer-title column-row"><strong>{{TitleCol3}}</strong></span>
                                <div v-if="row.fields.Column == 3"  v-for="(row, idx) in footerItems" :key="idx">
                                    <a  class="contact" v-if="row.fields.Type == 0" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-map-marker-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 1" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-phone-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 2" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-envelope"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a v-else :href="row.fields.PagePath" class="">
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                </div>
                            </div>
                            <div v-if="size === 4" class="column footer-content">
                                <span class="footer-title column-row"><strong>{{TitleCol4}}</strong></span>
                                <div v-if="row.fields.Column == 4" v-for="(row, idx) in footerItems" :key="idx">
                                    <a  class="contact" v-if="row.fields.Type == 0" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-map-marker-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 1" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-phone-alt"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a class="contact" v-else-if="row.fields.Type == 2" :href="row.fields.PagePath" target="_blank">
                                        <i class="icon fas fa-envelope"></i> 
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                    <a v-else :href="row.fields.PagePath" class="">
                                        <p>{{row.fields.Text}}</p>
                                    </a>
                                </div>
                            </div>
                        
                        </div>


                        <div class="row-container columns-2 container-bottom-links" v-if="!useModal">
                            <a :href="policy" class="policy bottom-links">Políticas de privacidad</a>
                            <a :href="terms" class="terms bottom-links">Términos y condiciones</a>
                        </div>
                        <div class="row-container columns-2 container-bottom-links" v-else>
                            <a @click="displayModal('policy') " class="policy bottom-links">Políticas de privacidad</a>
                            <a @click="displayModal('terms')" class="terms bottom-links">Términos y condiciones</a>
                        </div>
                    </div>
                </div>
                 <template v-if="footerTemplateCode">
                    <htmlSectionComponent :templateCode="footerTemplateCode" />
                 </template>
                 <template v-else>
                    <div class="row justify-content-center py-3">
                         <div class="col-12 col-md-6 text-center text-md-left">
                            Copyright © {{year}} <a href="#" class="brand">{{menuTitle}}</a> All rights reserved.
                        </div>
                        <div class="col-12 col-md-6 text-center text-md-right mt-2 mt-md-0">
                            <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                        </div>
                    </div>
                </template>
            </footer>`;
    }
}

footerSectionComponent.registerComponent();